import { React, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";

const HomePage = lazy(() => import("./components/HomePage"));
const AboutPage = lazy(() => import("./components/Pages/AboutPage"));
const GalleryPage = lazy(() => import("./components/Pages/GalleryPage"));
const StartupPage = lazy(() => import("./components/Pages/StartupPage"));
const EventPage = lazy(() => import("./components/Pages/EventPage"));
const ScrollToTop = lazy(() => import("./components/ScrollTop"));
const NotFound = lazy(() => import("./components/404"));

function App() {
  return (
    <>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/aboutus" element={<AboutPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/startups" element={<StartupPage />} />
            <Route path="/events" element={<EventPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </>
  );
}

export default App;
